<template>
    <div
        class="absolute border border-gray-300 bg-white pt-2 pb-1 z-20 overflow-scroll"
        :class="customClass"
    >
        <div class="flex space-x-1 px-3 items-center" v-if="!isNotSearchBox">
            <Icon name="SearchIcon" color="gray-400" size="3" isOutline />
            <input
                class="border-none outline-none ring-0 focus:border-none text-xs focus:outline-none focus:ring-0"
                autocomplete="off"
                placeholder="Type to find an option"
                v-model="searchText"
                @keyup.enter="
                    () => {
                        if (
                            !isNotAdd &&
                            get(searchText, 'length') > 0 &&
                            get(getOpportunities(), 'length') === 0
                        )
                            $emit('onAdd', searchText);
                    }
                "
            />
        </div>

        <div
            class="overflow-y-scroll space-y-1.5 pt-1.5 px-2 pb-2"
            style="max-height: 100px"
        >
            <div
                v-for="opportunity in getOpportunities()"
                @click="() => $emit('onClickItem', opportunity)"
                :key="get(opportunity, 'id')"
                class="flex justify-between items-center py-1 border rounded cursor-pointer px-2"
                :class="
                    (selectedOpportunities || []).find(
                        (o) => get(o, 'id') === get(opportunity, 'id')
                    )
                        ? 'border-indigo-500'
                        : 'border-gray-300 hover:border-gray-500'
                "
            >
                <Text
                    size="xs"
                    :content="get(opportunity, 'name')"
                    :color="
                        (selectedOpportunities || []).find(
                            (o) => get(o, 'id') === get(opportunity, 'id')
                        )
                            ? 'indigo-500'
                            : 'black'
                    "
                    custom-class="w-72 truncate"
                />
            </div>
        </div>

        <div
            class="border-t-2 space-x-2 border-gray-300 flex justify-between items-center pt-1 mx-2"
        >
            <div
                v-if="
                    !isNotAdd &&
                    get(searchText, 'length') > 0 &&
                    get(getOpportunities(), 'length') === 0
                "
                class="flex items-center space-x-2"
            >
                <Text
                    size="sm"
                    color="indigo-600"
                    :content="`+ Add new item named &ldquo;${searchText}&rdquo;`"
                    custom-class="cursor-pointer"
                    @click="() => $emit('onAdd', searchText)"
                />
                <svg
                    v-if="isLoading"
                    class="w-5 h-5 animate-spin ml-2"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </div>
            <div v-else></div>
            <Button
                v-if="get(selectedOpportunities, 'length') > 0"
                :isLoading="isLoading"
                content="Done"
                custom-class="px-2 py-1"
                @click="() => $emit('onDone')"
            />
        </div>
    </div>
</template>

<script>
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
import Button from "../../../atoms/Button/Button.vue";
import { get } from "lodash";

/**
 * - Use it to show a LinkedRecordList
 */
export default {
    components: {
        Text,
        Icon,
        Button,
    },
    props: {
        opportunities: {
            type: Array,
            default: () => [],
        },
        selectedOpportunities: {
            type: Array,
            default: () => [],
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        customClass: {
            type: String,
            default: "",
        },
        isNotAdd: {
            type: Boolean,
            default: false,
        },
        isNotSearchBox: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            get,
            searchText: null,
        };
    },
    methods: {
        getOpportunities() {
            if (this.searchText) {
                return this.opportunities?.filter((o) =>
                    o?.name
                        ?.toLowerCase()
                        ?.includes(this.searchText?.toLowerCase())
                );
            } else {
                return this.opportunities;
            }
        },
    },
};
</script>
