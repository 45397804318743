<template>
	<div class="border border-gray-300 hover:border-primary-700 shadow-lg relative" :class="`bg-${bgColor} ${customClass}`">
		<label :for="get(accordion, 'name')" class="flex items-center justify-between pr-4 box-border">
			<div class="flex space-x-2 items-center">
				<Text :customClass="`${isOpen ? 'font-semibold' : 'text-gray-900'
					} px-3 py-3`" size="md" weight="medium" :content="get(accordion, 'name', '').split('_')[0]" />
				<Text @click.stop="() => $emit('onEdit')" size="xs" color="gray-600" custom-class="underline cursor-pointer"
					v-if="isEdit" content="Edit" />
			</div>
		</label>
		<div class="absolute right-3 top-3 flex items-center space-x-2">
			<slot name="heading"></slot>
			<Icon name="ChevronDownIcon" size="5" isOutline color="gray-400" :customClass="`transition-all duration-200 transform ${isOpen ? 'rotate-180' : 'rotate-0'
				}`" />
		</div>
		<input type="checkbox" :id="get(accordion, 'name')" v-model="isOpen" hidden />
		<div class="
																								content
																								w-full
																								overflow-hidden
																								box-border
																								transition-all
																								ease-in-out
																								duration-200
																						" :style="
																							isOpen ? { maxHeight: maxHeight + 'px' } : { maxHeight: '0px' }
																						" :ref="get(accordion, 'name')">
			<slot v-if="leading" />
			<div v-else-if="isEditor" class="m-2 border border-gray-300 rounded-md">
				<editor-content :editor="editor" class="p-3" />
			</div>
			<div v-else class="p-4">
				<div :class="`bg-${bgColor} text-md space-y-3`">
					<TextArea :isHtml="false" label="" v-model:value="accordion.content" placeholder="Type here"
						custom-class="mr-1  border border-gray-300" v-if="accordion && accordion.type === 'textArea'" />
					<div v-for="lists in get(accordion, 'content')" v-else>
						<Text :content="lists.title" />
						<div v-for="item in lists.items" :key="item" class="text-sm" :class="`${lists.title === '' && 'mb-4'}`">
							{{ item }}
						</div>
					</div>
				</div>
			</div>
			<div class="p-4">

			</div>
			<div v-if="!hideBottom" class="
																																											                    flex
																																											                    justify-between
																																																					items-end
																																											                    gap-4
																																											                    p-4
																																											                    bg-gray-100
																																											                    border-t
																																											                    rounded-b-md
																																											                ">
				<div>
					<slot name="help" />
				</div>
				<div class="flex items-center gap-4">
					<Button content="Cancel" :variant="null" customClass="bg-white text-black underline px-2 bg-gray-100" />
					<Button @click="$emit('onSave')" content="Save" variant="primary" :is-loading="isLoading" />
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import Icon from "../../../atoms/Icons/Icons.vue";
import Button from "../../../atoms/Button/Button.vue";
import Link from "../../../atoms/Link/Link.vue";
import TextArea from "../../Textareas/Simple/Simple.vue";
import { Editor, EditorContent } from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import ListItem from '@tiptap/extension-list-item'
import OrderedList from '@tiptap/extension-ordered-list'
import BulletList from '@tiptap/extension-bullet-list'
import {get} from 'lodash'

/**
 * - Use it when show tabs with underline and icons
 * - tabs is required. default is empty array
 */
export default {
	components: {
		Text,
		Icon,
		Button,
		Link,
		TextArea,
		EditorContent
	},
	props: {
		bgColor: {
			type: String,
			default: "white",
		},
		accordion: {
			type: Object,
			default: () => {
			},
			required: true,
		},
		leading: {
			type: Boolean,
			default: false,
		},
		hideBottom: {
			type: Boolean,
			default: false,
		},
		customClass: {
			type: String,
			default: "",
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		isEditor: {
			type: Boolean,
			default: false,
		},
		isEdit: {
			type: Boolean,
			default: false,
		},
		isInitialOpen: {
			type: Boolean,
			default: false,
		}
	},
	data() {
		return {
			isOpen: this.isInitialOpen,
			maxHeight: 0,
			editor: null,
			get,
		};
	},
	watch: {
		isInitialOpen: function (val) {
			this.isOpen = val
		},
		isOpen: function (val) {
			if (val) {
				this.$emit('onOpen')
			}
			this.$emit('setOpen', val)
		}
	},
	mounted() {
		try {
			if (this.isEditor) {
				this.editor = new Editor({
					content: get(this.accordion, 'content'),
					extensions: [
						StarterKit,
						BulletList,
						OrderedList,
						ListItem,
					],
					onUpdate: (val) => {
						console.log(this.editor.getHTML(), 'val');
						this.maxHeight = get(this.$refs[get(this.accordion, 'name')], 'scrollHeight');
						this.$emit('onSaveEditor', this.editor.getHTML())
					}
				})
			}
			this.maxHeight = get(this.$refs[get(this.accordion, 'name')], 'scrollHeight');
		} catch (e) {
			console.log(e)
		}
	},
	beforeUnmount() {
		if (this.editor) this.editor.destroy()
	},
	beforeUpdate() {
		setTimeout(() => {
			this.maxHeight = get(this.$refs[get(this.accordion, 'name')], 'scrollHeight');
		}, 50)
	},
};
</script>

<style>
ul {
	list-style-type: disc;
	padding: 10px 0 !important;
	padding-left: 20px !important;
}

ol {
	list-style-type: decimal;
	padding: 10px 0 !important;
	padding-left: 20px !important;
}
</style>