<template>
  <!-- <Text :content="title" size="xl" weight="bold"/> -->
  <div class="w-full py-4 space-y-12 overflow-x-auto pb-40">
    <div class="w-full" v-for="(demographic, index) in demographics" :key="index">
      <Demographic :cells="demographic.content" :title="demographic.title" />
    </div>
  </div>
</template>

<script>
import Text from "../../atoms/Text/Text.vue";
import Demographic from "../../molecules/Demographic/Demographic.vue"

export default {
  name: "DemographicDataSummary",
  components: {
    Text,
    Demographic
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    demographics: {
      type: Array,
      default: () => [],
    }
  },
}
</script>
