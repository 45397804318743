<template>
	<TransitionRoot as="template" :show="open">
		<Dialog as="div" class="fixed inset-0 z-50 overflow-y-auto" @close="open = false">
			<div class="flex items-end justify-center min-h-screen text-center">
				<TransitionChild 
					as="template" 
					enter="ease-out duration-300" 
					enter-from="opacity-0" 
					enter-to="opacity-100"	 
					leave="ease-in duration-200" 
					leave-from="opacity-100" 
					leave-to="opacity-0"
				>
					<DialogOverlay class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75"/>
				</TransitionChild>

				<!-- This element is to trick the browser into centering the modal contents. -->
				<span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
				<TransitionChild 
					as="template" 
					enter="ease-out duration-300"
					enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
					enter-to="opacity-100 translate-y-0 sm:scale-100" 
					leave="ease-in duration-200"
					leave-from="opacity-100 translate-y-0 sm:scale-100"
					leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
				>
					<div class="fixed flex flex-col w-full h-screen px-4 overflow-hidden text-left transition-all transform bg-white shadow-xl xs:max-w-lg sm:max-w-lg sm:p-6">
						<div class="flex justify-between flex-none mt-8 mb-8">
							<Text size="2xl" weight="semibold" color="gray-900" :content="title"/>
							<Icon name="XIcon" size="6" isOutline color="gray-600" :onClick="onNoClick"/>
						</div>
						<div class="flex-1 w-full h-full mt-3 overflow-y-auto text-left">
							<div class="h-full overflow-y-auto text-md" v-html="textHtml">
							</div>
						</div>
						<div class="hidden sm:flex justify-end flex-none pb-4">
							<Button content="Close" variant="secondary" :onClick="onNoClick"/>
						</div>
						<!-- <div class="flex flex-col h-7/8">
						</div> -->
					</div>
				</TransitionChild>
			</div>
		</Dialog>
	</TransitionRoot>
</template>

<script>
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'
import Icon from '../../../atoms/Icons/Icons.vue'
import Button from '../../../atoms/Button/Button.vue'
import Text from '../../../atoms/Text/Text.vue'
/**
 * - Use it to show a simple modal.
 */
export default {
	components: {
		Dialog,
		DialogOverlay,
		DialogTitle,
		TransitionChild,
		TransitionRoot,
		Icon, Button, Text
	},
	props: {
		/**
		 * Use it for modal title
		 */
		title: {
			type: String,
			default: ''
		},
		/**
		 * Use it for modal description
		 */
		textHtml: {
			type: String,
			default: ''
		},
		/**
		 * Use it for yes button text
		 */
		yesBtnText: {
			type: String,
			default: ''
		},
		/**
		 * Use it for yes button text
		 */
		open: {
			type: Boolean,
			default: true
		},
		/**
		 * Use it for no button text
		 */
		noBtnText: {
			type: String,
			default: ''
		},
		/**
		 * Use it for modal icon
		 */
		icon: {
			type: String,
			default: ''
		},
		/**
		 * Use it for yes button action
		 */
		onYesClick: {
			type: Function,
			default: () => {}
		},
		/**
		 * Use it for no button action
		 */
		onNoClick: {
			type: Function,
			default: () => {}
		}
	},
}
</script>
